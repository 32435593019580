import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { graphql } from "gatsby";
import urljoin from "url-join";
import { DiscussionEmbed } from "disqus-react";
import { MDXRenderer } from "gatsby-plugin-mdx";

import Card from "../components/Card";
import { Text, Button, Heading } from "../components/Primitives";
import BaseLink from "../components/Link";
import SEO from "../components/SEO";
import Fancy from "../components/Fancy";
import Tag from "../components/Tag";
import {
  PrevNext,
  PostTitle,
  PostInfo,
  PostTags,
  PostContent,
} from "./StyledComponents";
import TableOfContents from "../components/TableOfContents";
// import Newsletter from "../components/Newsletter";

// It breaks the bullet alignment in lists.
// I don't remember why a link needs to be
// inline-block; maybe I should change the Link
// component instead of overriding the styles.
const Link = styled(BaseLink)`
  display: inline;
`;

const Post = ({
  pageContext: { previous, next },
  data: {
    mdx: {
      fields: { slug },
      frontmatter: {
        date,
        updated,
        title: postTitle,
        tags,
        description,
        skipTOC,
      },
      timeToRead,
      body,
      tableOfContents,
    },
    site: {
      siteMetadata: { title, siteUrl },
    },
  },
}) => {
  const disqusShortname = "devdiary-1";
  const disqusConfig = {
    identifier: slug,
    title,
  };
  const [showComments, setShowComments] = useState(false);
  return (
    <Fragment>
      <SEO
        title={`${postTitle} | ${title}`}
        description={description}
        image={urljoin(
          siteUrl,
          "social-cards",
          `${slug.replace(/\//g, "")}.png`
        )}
        url={urljoin(siteUrl, slug)}
        keywords={tags.toString()}
      />
      <Card>
        <PostTitle>{postTitle}</PostTitle>
        <PostInfo>
          <Fancy data-test-id="post-date">{date}</Fancy>
          <Fancy>
            <span>Time to read: </span>
            {timeToRead}
            <span> mins</span>
          </Fancy>
        </PostInfo>
        <PostTags data-test-id="post-tags">
          {tags.map((tag) => (
            <Tag key={tag} tag={tag} />
          ))}
        </PostTags>
        {updated && (
          <Text mb={4}>
            <Fancy>
              <b>Last update: </b>
              {updated}
            </Fancy>
          </Text>
        )}
        {!skipTOC && tableOfContents.items && (
          <TableOfContents data={tableOfContents} />
        )}
        <PostContent data-test-id="post-content">
          <MDXRenderer>{body}</MDXRenderer>
        </PostContent>

        {/* <Newsletter /> */}

        <Heading mt={5} mb={4}>
          Other things to read
        </Heading>
        <Heading as="h3" mt={4} mb={3}>
          Popular
        </Heading>
        <ul style={{ paddingLeft: 0 }}>
          <li>
            <Link
              color="var(--link)"
              to="/scroll-reveal-animations-with-react-spring/"
            >
              Reveal animations on scroll with react-spring
            </Link>
          </li>
          <li>
            <Link
              color="var(--link)"
              to="/gatsby-background-image-example/"
            >
              Gatsby background image example
            </Link>
          </li>
          <li>
            <Link
              color="var(--link)"
              to="/extremely-fast-load-time-with-gatsby-and-self-hosted-fonts/"
            >
              Extremely fast loading with Gatsby and self-hosted fonts
            </Link>
          </li>
        </ul>
        <Heading as="h3" mt={4} mb={3}>
          Previous/Next
        </Heading>
        <PrevNext>
          <li>
            {previous && (
              <Link color="var(--link)" to={previous.slug} rel="prev">
                ← {previous.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link color="var(--link)" to={next.slug} rel="next">
                {next.title} →
              </Link>
            )}
          </li>
        </PrevNext>
        {showComments ? (
          <>
            <sup>
              <strong> - tip: </strong>
              reload the page if you
              <strong> changed theme </strong>
              and disqus styling is messed up.
            </sup>
            <DiscussionEmbed
              shortname={disqusShortname}
              config={disqusConfig}
              data-test-id="disqus-comments"
            />
          </>
        ) : (
          <Button
            data-test-id="disqus-show-comments"
            variant="green"
            onClick={() => setShowComments(true)}
          >
            Load comments
          </Button>
        )}
      </Card>
    </Fragment>
  );
};

Post.propTypes = {
  pageContext: PropTypes.shape({
    // eslint-disable-next-line
    previous: PropTypes.object,
    // eslint-disable-next-line
    next: PropTypes.object,
  }).isRequired,
  data: PropTypes.shape({
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        siteUrl: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    mdx: PropTypes.shape({
      fields: PropTypes.shape({
        slug: PropTypes.string.isRequired,
      }).isRequired,
      frontmatter: PropTypes.shape({
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
        tags: PropTypes.arrayOf(PropTypes.string).isRequired,
        updated: PropTypes.string,
        skipTOC: PropTypes.bool,
      }).isRequired,
      timeToRead: PropTypes.number.isRequired,
      body: PropTypes.string.isRequired,
      tableOfContents: PropTypes.shape({
        items: PropTypes.arrayOf(
          PropTypes.shape({
            url: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            // eslint-disable-next-line react/forbid-prop-types
            items: PropTypes.arrayOf(PropTypes.object),
          })
        ).isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

export const pageQuery = graphql`
  query NoteBySlug($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      frontmatter {
        title
        description
        date(formatString: "DD MMMM, YYYY")
        tags
        updated(formatString: "DD MMMM, YYYY")
        skipTOC
      }
      timeToRead
      body
      tableOfContents(maxDepth: 5)
    }
    site {
      siteMetadata {
        siteUrl
        title
      }
    }
  }
`;

export default Post;
