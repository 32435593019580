import styled from "styled-components";

const Fancy = styled.div`
  font-weight: 300;
  font-family: var(--font-family-ui);
  font-variation-settings: "wght" 61;
  font-size: 15px;
  color: var(--color-extreme);
  font-kerning: normal;
  font-variant-ligatures: common-ligatures contextual;
  font-variant-numeric: oldstyle-nums proportional-nums;
  font-feature-settings: "kern", "liga", "clig", "calt", "onum", "pnum";
  line-height: 1.3;
`;

export default Fancy;
