import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { tocEntryType } from "../types";

const Details = styled.details`
  margin-top: calc(var(--rhythm) * 2);

  ul ul {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

const List = styled.ul`
  margin: var(--rhythm) 0;
  margin-left: calc(var(--rhythm) / 2) !important;
  padding-left: 0;
`;

const Item = styled.li`
  margin-bottom: calc(var(--rhythm) / 8);
`;

const Link = styled.a`
  word-break: break-word;
  border-bottom: 1px solid var(--link);

  &:focus {
    box-shadow: 0 0 0 2px var(--accent);
  }

  &:hover,
  &:focus {
    text-decoration: none;
    border-bottom: none;
    background-color: var(--accent);
    color: var(--bg);
  }
`;

const SubList = ({ items }) => {
  return (
    <List>
      {items.map(({ url, title, items: nestedItems }) => (
        <Item key={title}>
          <Link href={url}>{title}</Link>
          {nestedItems && <SubList items={nestedItems} />}
        </Item>
      ))}
    </List>
  );
};

SubList.propTypes = {
  items: PropTypes.arrayOf(tocEntryType).isRequired,
};

const TableOfContents = ({ data }) => (
  <Details>
    <summary>
      <strong>Table of contents</strong>
    </summary>
    <SubList items={data.items} />
  </Details>
);

TableOfContents.propTypes = {
  data: PropTypes.shape({
    items: PropTypes.arrayOf(tocEntryType).isRequired,
  }).isRequired,
};

export default TableOfContents;
