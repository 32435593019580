import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import slugify from "slugify";

import Link from "./Link";

const StyledTagLink = styled(Link)`
  font-family: var(--font-family-ui);
  font-variation-settings: "wght" var(--light);
  display: inline-block;
  background-color: #4c94c3 !important;
  color: #fff;
  font-size: 1.35em;
  border-radius: 5px;
  padding: 0 calc(var(--rhythm) / 4);
  margin-right: calc(var(--rhythm) / 8);
  margin-bottom: calc(var(--rhythm) / 8);
  border: 1px solid #3f3f37;
  border-bottom-width: 2px;
  border-right-width: 2px;
  transition: background-color 0.15s ease-out;

  :hover,
  :focus {
    color: var(--accent-text);
    background-color: var(--accent) !important;
    box-shadow: none;
  }
`;
const Tag = ({ tag }) => (
  <StyledTagLink to={`/tags/${slugify(tag, { lower: true })}/`}>
    {tag}
  </StyledTagLink>
);

Tag.propTypes = {
  tag: PropTypes.string.isRequired,
};

export default Tag;
