import styled from "styled-components";

export const PrevNext = styled.ul`
  margin-left: 0 !important;
  margin-bottom: var(--rhythm);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style: none;
  padding: 0;

  li {
    margin-left: 0;
    width: 48%;
    text-align: left;
  }

  li:nth-child(2) {
    text-align: right;
  }
  @media screen and (max-width: 414px) {
    li {
      text-align: left !important;
      width: 100%;
      margin-top: calc(var(--rhythm) / 2);
    }
  }
`;
export const PostTitle = styled.h1`
  margin: var(--rhythm) 0;
  font-size: 2.35em;
  @media screen and (max-width: 576px) {
    font-size: 2em;
  }
`;
export const PostInfo = styled.div`
  margin-bottom: calc(var(--rhythm) / 2);
`;
export const PostTags = styled.div`
  margin-bottom: calc(var(--rhythm) / 2);
`;
export const PostContent = styled.div`
  a {
    word-break: break-word;
    text-decoration: none;
    border-bottom: 1px solid var(--link);
  }

  a:hover,
  a:focus {
    text-decoration: none;
    background-color: var(--accent);
    color: var(--bg);
    border-bottom: none;
  }

  a:focus {
    box-shadow: 0 0 0 2px var(--accent);
  }

  a:focus:not(:focus-visible) {
    box-shadow: none;
  }

  a.heading-link {
    color: var(--color);
    text-decoration: none;
    border-bottom: none;
  }

  a.heading-link:hover,
  a.heading-link:focus {
    color: var(--color);
    background-color: var(--bg-content);
    text-decoration: underline;
  }

  margin-bottom: var(--rhythm);

  ol {
    padding-left: calc(var(--rhythm) / 2);
  }

  ul {
    margin-bottom: var(--rhythm);
    padding-left: 0;
  }

  ul p,
  ol p {
    margin: 0;
    margin-bottom: calc(var(--rhythm) / 8);
  }

  li {
    margin-bottom: calc(var(--rhythm) / 8);
  }
  @media screen and (max-width: 576px) {
    h2 {
      font-size: 1.33rem;
    }

    h3 {
      font-size: 1.2rem;
    }
  }

  ul ul {
    margin-top: 0;
    margin-bottom: 0;
  }

  h2 {
    font-size: 1.8em;
  }

  h3 {
    font-size: 1.35em;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: var(--rhythm);
    margin-top: calc(var(--rhythm) * 2);
  }
`;
