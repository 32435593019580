import { shape, number, string, arrayOf } from "prop-types";

export const previewPostType = shape({
  title: string.isRequired,
  description: string.isRequired,
  slug: string.isRequired,
  timeToRead: number.isRequired,
  date: string.isRequired,
  tags: arrayOf(string).isRequired,
});

export const tocEntryType = shape({
  url: string.isRequired,
  title: string.isRequired,
});
tocEntryType.items = arrayOf(tocEntryType);
